/* eslint-disable max-len */
import React from 'react';
import './App.css';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from './authConfig';
import MenuTabs from './components/MenuTabs';

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  React.useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
  });

  return (
    <div>
      <AuthenticatedTemplate>
        <header className="App-header" />
        <MenuTabs />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Signing in...</div>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
