/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, Button, TextField } from '@mui/material';
import * as React from 'react';
import { Ingredient } from '../../Models/RecipeModel';

type Props = {
  existingDescription: string;
  // eslint-disable-next-line react/require-default-props
  ingredients?: Ingredient[];
  title: string;
  onDescriptionChanged: (description: string) => void;
}

function DescriptionForm(props: Props) {
  const {
    onDescriptionChanged, existingDescription, ingredients, title,
  } = props;
  const [description, setDescription] = React.useState<string>(existingDescription || '');
  const [selectedIngredient, setSelectedIngredient] = React.useState<Ingredient | null>(null);

  // eslint-disable-next-line max-len
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDescription(event.target.value);
    onDescriptionChanged(event.target.value);
  };

  const onAddIngredient = () => {
    if (!selectedIngredient) return;

    const newDescription = `${description}{${selectedIngredient.id}}`;
    setDescription(newDescription);
    onDescriptionChanged(newDescription);
    setSelectedIngredient(null);
  };

  const renderAutoComplete = () => {
    if (!ingredients) return null;

    return (
      <div style={{
        display: 'flex', alignContent: 'center', justifyContent: 'center', gap: 10,
      }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={ingredients}
          getOptionLabel={(option) => `${option.name} ${option.amount} ${option.measure}`}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Ingredients" />}
          onChange={(_, value) => setSelectedIngredient(value)}
          value={selectedIngredient}
        />
        <Button variant="outlined" onClick={() => onAddIngredient()}>+</Button>
      </div>
    );
  };

  return (
    <section style={{
      border: '1px solid #fb8c00', display: 'flex', flexDirection: 'column', gap: 20, padding: 10, maxWidth: '100%',
    }}
    >
      <h5 style={{ color: '#fb8c00', margin: 0 }}>{title}</h5>
      <TextField multiline maxRows={5} minRows={5} onChange={(event) => onInputChange(event)} label={title} value={description} />
      {renderAutoComplete()}
    </section>
  );
}

export default DescriptionForm;
