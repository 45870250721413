import { isLocalHost } from '../../helpers/helper';
import { RecipeModel } from '../../Models/RecipeModel';

export const upsertRecipe = async (recipe: RecipeModel, edit: boolean, token: string) => {
  let url = '';
  if (!edit) {
    url = isLocalHost() ? 'http://localhost:7127/api/recipe' : 'https://babyforum-apim.azure-api.net/admin/recipe';
  } else {
    url = isLocalHost() ? 'http://localhost:7127/api/recipe/update' : 'https://babyforum-apim.azure-api.net/admin/recipe/update';
  }

  // const url = 'https://babyforum-apim.azure-api.net/admin/recipe';
  const response = await fetch(url, { method: edit ? 'PUT' : 'POST', headers: new Headers({ Authorization: `Bearer ${token}` }), body: JSON.stringify({ ...recipe }) });
  return response;
};

export const getAllRecipes = async (token: string): Promise<RecipeModel[]> => {
  // const url = isLocalHost() ? 'http://localhost:7071/api/recipes' : 'https://babyforum-apim.azure-api.net/admin/recipes';
  const url = 'https://babyforum-apim.azure-api.net/admin/recipes';
  const response = await fetch(url, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) });
  return response.json();
};

export const deleteRecipe = async (id: string, token: string): Promise<Response> => {
  const url = isLocalHost() ? `http://localhost:7071/api/recipe/delete/${id}` : `https://babyforum-apim.azure-api.net/admin/recipe/delete/${id}`;
  const response = await fetch(url, { method: 'DELETE', headers: new Headers({ Authorization: `Bearer ${token}` }) });
  return response;
};
