/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';
import * as React from 'react';
import { getIdToken, getImagePreview } from '../../helpers/helper';
import ImageService from '../../services/common/ImageService';
import AddImageDialog from './AddImageDialog';

type Props = {
  onImageChanged: (file: File | null) => void;
  existingImgUrl: string;
  buttonTitle: string;
}

function AddImage(props: Props) {
  const msal = useMsal();

  const { onImageChanged, existingImgUrl, buttonTitle } = props;
  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  const [imagePreview, setImagePreview] = React.useState<string>(existingImgUrl || '');
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [showImgPreview, setShowImgPreview] = React.useState<boolean>(!!existingImgUrl);

  const isCorrectFileType = (file: File): boolean => file && file.type.split('/')[0] === 'image';

  const fileListToArray = (list: FileList): File[] => {
    const array: File[] = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i) as File);
    }
    return array;
  };

  const onFilesAdded = async (evt: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!evt.target.files || !isCorrectFileType(evt.target.files[0])) return;
    const { files } = evt.target;

    getImagePreview(files[0], (imgPreview: string) => {
      setImagePreview(imgPreview);
      setOpenDialog(true);
    });

    const array = fileListToArray(files);
    onImageChanged(array[0]);
    setShowImgPreview(false);
  };

  const dialogClosed = () => {
    setOpenDialog(false);
    setShowImgPreview(false);
  };

  return (
    <div>
      {showImgPreview ? (
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'center', marginBottom: 10,
        }}
        >
          <img
            style={{
              objectFit: 'cover', width: '100%', boxShadow: '0px 3px 6px #00000029', borderRadius: 10, height: 200,
            }}
            src={imagePreview}
            alt="img preview"
          />
        </div>
      ) : null}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Button component="label" variant="outlined">
          {buttonTitle}
          <input
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            type="file"
            id="icon-button-file"
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => onFilesAdded(event)}
          />
        </Button>
      </div>

      {openDialog && imagePreview && (
        <AddImageDialog
          useImg={() => { setShowImgPreview(true); setOpenDialog(false); }}
          handleClose={() => { dialogClosed(); }}
          imagePreview={imagePreview}
          open={openDialog}
        />
      )}
    </div>
  );
}

export default AddImage;
