/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { TextField } from '@mui/material';
import * as React from 'react';
import { CookingTime, OvenCooking, RecipeModel } from '../../Models/RecipeModel';

type Props = {
  onRecipeOverviewChanged: (propertyName: keyof RecipeModel, value: CookingTime | number | OvenCooking | null) => void;
  recipe: RecipeModel;
}

function RecipeOverviewForm(props: Props) {
  const { onRecipeOverviewChanged, recipe } = props;

  const onInputChange = (name: keyof CookingTime | keyof RecipeModel) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (name === 'toCookFor') { onRecipeOverviewChanged('toCookFor', parseInt(value, 10)); }
    if (name === 'amount' || name === 'measure') { onRecipeOverviewChanged('cookingTime', { ...recipe.cookingTime, [name]: name === 'amount' ? parseInt(value, 10) : value }); }
  };

  const onOvenCookingChanged = (name: keyof OvenCooking) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value;

    if (!recipe.ovenCooking && eventValue) {
      let value = null;
      if (name === 'amount') {
        value = { amount: parseInt(eventValue, 10), measure: '' } as OvenCooking;
      } else {
        value = { amount: 0, measure: eventValue } as OvenCooking;
      }

      onRecipeOverviewChanged('ovenCooking', value);
      return;
    }

    if ((!recipe.ovenCooking?.amount || !recipe.ovenCooking?.measure) && !eventValue) {
      console.log('clearing form');
      onRecipeOverviewChanged('ovenCooking', null);
      return;
    }

    onRecipeOverviewChanged('ovenCooking', { ...recipe.ovenCooking as OvenCooking, [name]: name === 'amount' ? parseInt(eventValue, 10) : eventValue });
  };

  return (
    <section style={{
      border: '1px solid #fb8c00', display: 'flex', flexDirection: 'column', gap: 20, padding: 10, maxWidth: '100%',
    }}
    >
      <section>
        <h5 style={{ color: '#fb8c00', margin: 0, marginBottom: 10 }}>Hur lång tid att göra?</h5>
        <TextField label="Amount" onChange={onInputChange('amount')} value={recipe.cookingTime.amount ? recipe.cookingTime.amount : ''} />
        <TextField label="Measure" onChange={onInputChange('measure')} value={recipe.cookingTime.measure ? recipe.cookingTime.measure : ''} />
      </section>
      <section>
        <h5 style={{ color: '#fb8c00', margin: 0, marginBottom: 10 }}>Ugnsgrader</h5>
        <TextField label="Amount" onChange={onOvenCookingChanged('amount')} value={recipe.ovenCooking ? recipe.ovenCooking.amount : ''} />
        <TextField label="Measure" onChange={onOvenCookingChanged('measure')} value={recipe.ovenCooking ? recipe.ovenCooking.measure : ''} />
      </section>
      <section>
        <TextField label="To cook for" onChange={onInputChange('toCookFor')} value={recipe.toCookFor ? recipe.toCookFor : ''} />
      </section>
    </section>
  );
}

export default RecipeOverviewForm;
