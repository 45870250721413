import { createTheme, ThemeOptions } from '@mui/material';
import red from '@mui/material/colors/red';

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: '#fb8c00',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            color: 'white',
          },
          '&:hover label': {
            fontWeight: 700,
          },
          '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
          },
          '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
              borderWidth: 2,
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          '& label': {
            color: 'white',
          },
        },
        icon: {
          color: 'white',
        },
      },
    },
  },
} as ThemeOptions);
