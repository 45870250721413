import { isLocalHost } from '../../helpers/helper';
import { MaterialModel } from '../../Models/MaterialModel';

export const upsertMaterial = async (material: MaterialModel, edit: boolean, token: string) => {
  let url = '';
  if (!edit) {
    url = isLocalHost() ? 'http://localhost:7071/api/material' : 'https://babyforum-apim.azure-api.net/admin/material';
  } else {
    url = isLocalHost() ? 'http://localhost:7071/api/material/update' : 'https://babyforum-apim.azure-api.net/admin/material/update';
  }

  // const url = 'https://babyforum-apim.azure-api.net/admin/material';
  const response = await fetch(url, { method: edit ? 'PUT' : 'POST', headers: new Headers({ Authorization: `Bearer ${token}` }), body: JSON.stringify({ ...material }) });
  return response;
};

export const getAllMaterial = async (token: string): Promise<MaterialModel[]> => {
  const url = isLocalHost() ? 'http://localhost:7071/api/materials' : 'https://babyforum-apim.azure-api.net/admin/materials';
  // const url = 'https://babyforum-apim.azure-api.net/admin/materials';
  const response = await fetch(url, { method: 'GET', headers: new Headers({ Authorization: `Bearer ${token}` }) });
  return response.json();
};

export const deleteMaterial = async (id: string, token: string): Promise<Response> => {
  const url = isLocalHost() ? `http://localhost:7071/api/material/delete/${id}` : `https://babyforum-apim.azure-api.net/admin/material/delete/${id}`;
  const response = await fetch(url, { method: 'DELETE', headers: new Headers({ Authorization: `Bearer ${token}` }) });
  return response;
};
