/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import { Button } from '@mui/material';
import * as React from 'react';
import { getImagePreview } from '../../helpers/helper';

type Props = {
  onFileChanged: (file: File | null) => void;
  existingFileUrl: string;
  buttonTitle: string;
}

function AddFile(props: Props) {
  const { onFileChanged, existingFileUrl, buttonTitle } = props;
  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

  const [filePreview, setFilePreview] = React.useState<string>(existingFileUrl || '');
  const [showFilePreview, setShowFilePreview] = React.useState<boolean>(!!existingFileUrl);

  const fileListToArray = (list: FileList): File[] => {
    const array: File[] = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i) as File);
    }
    return array;
  };

  const onFilesAdded = async (evt: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!evt.target.files) return;
    const { files } = evt.target;

    getImagePreview(files[0], (imgPreview: string) => {
      setFilePreview(imgPreview);
    });

    const array = fileListToArray(files);
    onFileChanged(array[0]);
    setShowFilePreview(true);
  };
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFilePreview('');
    onFileChanged(null);
    setShowFilePreview(false);
  };

  return (
    <div>
      {showFilePreview ? (
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'center', marginBottom: 10, height: 500,
        }}
        >
          <object data={filePreview} type="application/pdf">test</object>
        </div>
      ) : null}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Button component="label" variant="outlined">
          {buttonTitle}
          <input
            ref={fileInputRef}
            style={{ display: 'none' }}
            type="file"
            id="icon-button-file"
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => onFilesAdded(event)}
          />
        </Button>
        <Button variant="outlined" type="submit" onClick={() => resetFileInput()}>Ta bort fil</Button>
      </div>

    </div>
  );
}

export default AddFile;
