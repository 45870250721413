import { getAllMeetupSignups } from '../../services/meetup/MeetupService';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { MeetupSignupInfo } from '../../Models/Meetup';
import { useEffect, useState } from 'react';
import { getIdToken } from '../../helpers/helper';
import { useMsal } from '@azure/msal-react';

export function MeetupSignups() {
  const [meetupSignups, setMeetupSignups] = React.useState<MeetupSignupInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const msal = useMsal();

  useEffect(() => {
    const fetchMeetupSignups = async () => {
      try {
        setLoading(true);
        const signups = await getAllMeetupSignups(
          await getIdToken(msal.instance, msal.accounts[0]),
        );
        setMeetupSignups(signups);
      } catch (errorThrown: any) {
        setError(errorThrown.message);
      }

      setLoading(false);
    };
    fetchMeetupSignups();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ color: '#fb8c00' }}>Skapat</TableCell>
          <TableCell style={{ color: '#fb8c00' }}>Namn</TableCell>
          <TableCell style={{ color: '#fb8c00' }}>Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {meetupSignups.map((meetupSignup) => {
          const dateTime = new Date(meetupSignup.createdUtc);
          const date = dateTime.toISOString().split('T')[0];
          const time = dateTime.toISOString().split('T')[1].split('.')[0];

          return (
            <TableRow key={meetupSignup.email}>
              <TableCell style={{ color: '#fff' }}>{`${date} ${time}`}</TableCell>
              <TableCell style={{ color: '#fff' }}>{meetupSignup.name}</TableCell>
              <TableCell style={{ color: '#fff' }}>{meetupSignup.email}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
