const url = 'https://babyforum-apim.azure-api.net/admin/images/';
const fileUrl = 'https://babyforum-apim.azure-api.net/admin/files/';
// const fileUrl = 'http://localhost:7072/api/files/';

export interface IImageResponse {
  imageUrl: string;
}

export interface IFileResponse {
  fileUrl: string;
}

export default class ImageService {
  static async createImage(file: File, token: string): Promise<string> {
    const formData = new FormData();
    formData.append('Files', file);

    const results = await fetch(`${url}create`, { method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}` }), body: formData });
    const response = (await results.json()) as IImageResponse;
    return response.imageUrl;
  }

  static async createFile(file: File, token: string): Promise<string> {
    const formData = new FormData();
    formData.append('Files', file);

    const results = await fetch(`${fileUrl}create`, { method: 'POST', headers: new Headers({ Authorization: `Bearer ${token}` }), body: formData });
    const response = (await results.json()) as IFileResponse;
    return response.fileUrl;
  }
}
