/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import { BaseCosmosEntity } from './CommonModel';

export enum MaterialCategory {
    GUIDE,
    POSTER,
    CALENDAR,
    ARTICLE,
    FOODLIST,
    LEARNING,
    OTHER,
}

export interface MaterialModel extends BaseCosmosEntity {
  title: string;
  subCategoryId: MaterialCategory | null;
  description: string;
  shortDescription: string;
  imgUrl: string;
  downloadUrl: string;
}

export const materialSubCategories = [
  {
    value: 'Guide', id: MaterialCategory.GUIDE, slug: 'frukost',
  },
  {
    value: 'Poster', id: MaterialCategory.POSTER, slug: 'mellis',
  },
  {
    value: 'Kalender', id: MaterialCategory.CALENDAR, slug: 'maltid',
  },
  {
    value: 'Artikel', id: MaterialCategory.ARTICLE, slug: 'maltid',
  },
  {
    value: 'Matlista', id: MaterialCategory.FOODLIST, slug: 'maltid',
  },
  {
    value: 'Läromedel', id: MaterialCategory.LEARNING, slug: 'maltid',
  },
  {
    value: 'Övrigt', id: MaterialCategory.OTHER, slug: 'maltid',
  },
];
