/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { useMsal } from '@azure/msal-react';
import {
  Button,
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import * as React from 'react';
import { getIdToken } from '../../helpers/helper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CreateMaterialForm from './CreateMaterialForm';
import { MaterialModel, materialSubCategories } from '../../Models/MaterialModel';
import { deleteMaterial, getAllMaterial } from '../../services/material/MaterialService';

function MaterialList() {
  const [materials, setMaterials] = React.useState<MaterialModel[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [materialToEdit, setMaterialToEdit] = React.useState<MaterialModel | null>(null);
  const msal = useMsal();

  async function getMaterials() {
    const m = await getAllMaterial(await getIdToken(msal.instance, msal.accounts[0]));
    setMaterials(m);
  }

  React.useEffect(() => {
    getMaterials();
  }, []);

  const onDeleteClicked = async (id: string) => {
    const confirmed = confirm('Är du säker på att du vill ta bort detta material?');
    if (confirmed) {
      setLoading(true);
      const response = await deleteMaterial(id, await getIdToken(msal.instance, msal.accounts[0]));
      setLoading(false);
      // msal.instance.token;
      if (response.ok) {
        alert('Materialet borttaget');
        getMaterials();
      } else {
        alert(`Något gick fel: ${await response.text()}`);
      }
    }
  };

  const onEditClicked = (material: MaterialModel) => {
    setMaterialToEdit(material);
  };

  if (loading) return <div style={{ color: '#fff' }}>Laddar...</div>;

  if (materialToEdit) return <CreateMaterialForm edit material={materialToEdit} onSaveClicked={() => { setMaterialToEdit(null); getMaterials(); }} />;

  return (
    <section className="container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: '#fb8c00' }} />
            <TableCell style={{ color: '#fb8c00' }}>Titel</TableCell>
            <TableCell style={{ color: '#fb8c00' }}>Beskrivning</TableCell>
            <TableCell style={{ color: '#fb8c00' }}>Kategori</TableCell>
            <TableCell style={{ color: '#fb8c00' }}>Skapat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materials.map((material) => (
            <TableRow key={material.id}>
              <TableCell style={{ color: '#fff', width: 10 }}>
                <Button onClick={() => onEditClicked(material)}><EditIcon /></Button>
                {' '}
                <Button onClick={() => onDeleteClicked(material.id)} href="#"><DeleteIcon style={{ width: 20 }} /></Button>
              </TableCell>
              <TableCell style={{ color: '#fff' }}>{material.title}</TableCell>
              <TableCell style={{ color: '#fff' }}>{material.description}</TableCell>
              <TableCell style={{ color: '#fff' }}>{materialSubCategories.find((x) => x.id === material.subCategoryId)?.value}</TableCell>
              <TableCell style={{ color: '#fff' }}>{new Date(material.createdUtc).toISOString().split('T')[0]}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
}

export default MaterialList;
