import { Configuration, RedirectRequest } from '@azure/msal-browser';
import { isLocalHost } from './helpers/helper';

export const msalConfig: Configuration = {
  auth: {
    clientId: 'b3f3ae76-72a1-4dff-b849-71f2f72c2677',
    authority: 'https://login.microsoftonline.com/e7862bb7-c5ca-4449-8e7d-884e61d234b0',
    redirectUri: isLocalHost() ? 'http://localhost:3000' : 'https://admin.babyforum.nu',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ['User.Read'],
};
