/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { AccountInfo, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';

export const idToken = 'msal.idtoken';

export const isLocalHost = ():boolean => window.location.href.indexOf('localhost') > -1;

export const getIdToken = async (instance: IPublicClientApplication, account: AccountInfo): Promise<string> => {
  const accessTokenRequest = {
    scopes: ['user.read'],
    account,
  };

  try {
    const token = await instance.acquireTokenSilent(accessTokenRequest);
    return token.idToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      instance.acquireTokenRedirect(accessTokenRequest);
    }

    return '';
  }
};

export const getImagePreview = (image: File, callback: (imagePreview: string) => void): void => {
  const reader = new FileReader();
  reader.onload = function (e): void {
    const imagePreview = e.target ? (e.target.result as string) : '';

    callback(imagePreview);
  };

  reader.readAsDataURL(image);
};
