/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
  Button, Chip, ListItem, Stack, TextField,
} from '@mui/material';
import * as React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Ingredient } from '../../Models/RecipeModel';

type Props = {
  onIngredientsChange: (ingredients: Ingredient[]) => void;
  existingIngredients: Ingredient[] | null;
};

const emptyIngredient = {
  id: 0,
  amount: 0,
  measure: '',
  name: '',
} as Ingredient;

let currentIngredientId = 0;

function IngredientsForm(props: Props) {
  const { onIngredientsChange, existingIngredients } = props;
  const [ingredients, setIngredients] = React.useState<Ingredient[]>(existingIngredients || []);
  const [newIngredient, setNewIngredient] = React.useState<Ingredient>(emptyIngredient);

  React.useEffect(() => {
    currentIngredientId = 0;
    if (existingIngredients && existingIngredients.length > 0) {
      currentIngredientId = existingIngredients[0].id;
      for (let i = 1; i < existingIngredients.length; i++) {
        if (existingIngredients[i].id > currentIngredientId) {
          currentIngredientId = existingIngredients[i].id;
        }
      }
    }
  }, [existingIngredients]);

  const onDeleteIngredient = (id: number) => {
    setIngredients(ingredients.filter((v) => v.id !== id));
    onIngredientsChange(ingredients.filter((v) => v.id !== id));
  };

  const onAddIngredient = () => {
    const i = [...ingredients];
    const newState = { ...newIngredient, id: currentIngredientId + 1 };
    i.push(newState);

    setIngredients(i);
    setNewIngredient(emptyIngredient);
    onIngredientsChange(i);
    currentIngredientId += 1;
  };

  const onInputChange = (name: keyof Ingredient) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewIngredient({ ...newIngredient, [name]: name === 'amount' ? parseInt(event.target.value, 10) : event.target.value });
  };

  return (
    <section style={{
      border: '1px solid #fb8c00', display: 'flex', flexDirection: 'column', gap: 20, padding: 10, maxWidth: '100%',
    }}
    >
      <h5 style={{ color: '#fb8c00', margin: 0 }}>Skapa Ingredienser</h5>
      <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
        {ingredients.map((v) => (
          <Chip
            key={v.id}
            label={`${v.name} ${v.amount} ${v.measure} (id: ${v.id})`}
            onDelete={() => onDeleteIngredient(v.id)}
            variant="outlined"
            style={{ color: '#fb8c00' }}
            deleteIcon={<HighlightOffIcon style={{ color: '#fb8c00' }} />}
          />
        ))}
      </div>
      <TextField onChange={onInputChange('name')} label="Name" value={newIngredient?.name} />
      <TextField onChange={onInputChange('amount')} label="Amount" type="number" value={newIngredient?.amount} />
      <TextField onChange={onInputChange('measure')} label="Measure (dl, l, etc..)" value={newIngredient?.measure} />
      <Button onClick={() => { onAddIngredient(); }} variant="outlined">Add ingredient</Button>
    </section>
  );
}

export default IngredientsForm;
