/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import { BaseCosmosEntity } from './CommonModel';

export enum RecipeSubCategoryIdEnum {
  BREAKFAST,
  SNACK,
  MEAL
}

export interface Ingredient {
  id: number;
  amount: number;
  measure: string;
  name: string;
}

export interface Tag {
  iconUrl: string;
  measure: string;
}

export interface CookingTime {
  amount: number;
  measure: string;
}

export interface OvenCooking {
  amount: number;
  measure: string;
}

export interface RecipeModel extends BaseCosmosEntity {
  title: string;
  subCategoryId: RecipeSubCategoryIdEnum | null;
  description: string;
  imgUrl: string;
  tags: Tag[];
  ingredients: Ingredient[];
  cookingTime: CookingTime;
  toCookFor: number;
  ovenCooking: OvenCooking | null;
}

export const recipeSubCategories = [
  {
    value: 'Frukost', id: RecipeSubCategoryIdEnum.BREAKFAST, slug: 'frukost',
  },
  {
    value: 'Mellis', id: RecipeSubCategoryIdEnum.SNACK, slug: 'mellis',
  },
  {
    value: 'Måltid', id: RecipeSubCategoryIdEnum.MEAL, slug: 'maltid',
  },
];
