/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import * as React from 'react';
import TextField from '@mui/material/TextField/TextField';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Button from '@mui/material/Button/Button';
import { useMsal } from '@azure/msal-react';
import { getIdToken } from '../../helpers/helper';
import { Link } from '@mui/material';
import AddImage from '../common/AddImage';
import ImageService from '../../services/common/ImageService';
import { MaterialCategory, MaterialModel, materialSubCategories } from '../../Models/MaterialModel';
import { upsertMaterial } from '../../services/material/MaterialService';
import DescriptionForm from '../common/DescriptionForm';
import AddFile from '../common/AddFile';

const emptyState = {
  description: '',
  id: '',
  imgUrl: '',
  downloadUrl: '',
  shortDescription: '',
  subCategoryId: null,
  title: '',
  createdUtc: new Date(),
} as MaterialModel;

interface Props {
  edit: boolean;
  material: MaterialModel | null;
  onSaveClicked?: () => void;
}

function CreateMaterialForm(props: Props) {
  const { edit, material, onSaveClicked } = props;
  const [state, setState] = React.useState<MaterialModel>(material || emptyState);
  const [materialImg, setMaterialImg] = React.useState<File | null>(null);
  const [materialFile, setMaterialFile] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [savedMaterial, setSavedMaterial] = React.useState<boolean>(false);

  const msal = useMsal();

  // eslint-disable-next-line max-len
  const onInputChange = (name: keyof MaterialModel) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.value });
  };

  const onImageChanged = (file: File | null) => {
    setMaterialImg(file);
    if (file) { setState({ ...state, imgUrl: '' }); }
  };

  const onFileChanged = (file: File | null) => {
    setMaterialFile(file);
    if (file) { setState({ ...state, downloadUrl: '' }); }
  };

  const onSubmitClicked = async () => {
    setSavedMaterial(false);
    setLoading(true);

    let imgUrl = '';
    if (!state.imgUrl) {
      imgUrl = await ImageService.createFile(materialImg as File, await getIdToken(msal.instance, msal.accounts[0]));
    } else {
      imgUrl = state.imgUrl;
    }

    let downloadUrl = '';
    if (!state.downloadUrl && materialFile) {
      downloadUrl = await ImageService.createFile(materialFile as File, await getIdToken(msal.instance, msal.accounts[0]));
      setState({ ...state, downloadUrl });
    } else if (state.downloadUrl) {
      downloadUrl = state.downloadUrl;
    }

    const response = await upsertMaterial({ ...state, imgUrl, downloadUrl }, edit, await getIdToken(msal.instance, msal.accounts[0]));
    setLoading(false);

    if (response.ok) {
      setState(emptyState);
      setSavedMaterial(true);
      alert('Materialet sparat');
      if (onSaveClicked) { onSaveClicked(); }
    } else {
      alert(`Något gick fel: ${await response.text()}`);
    }
  };

  return loading ? <div style={{ color: '#fff' }}>Laddar...</div>
    : (
      <>
        <h4 style={{ color: '#fb8c00' }}>Skapa Recept</h4>
        {savedMaterial ? <Link href="https://babyforum.nu/material" style={{ marginBottom: 20, display: 'block' }}>Se det nya receptet</Link> : null}
        <div style={{
          display: 'flex', flexDirection: 'column', gap: 20, maxWidth: '100%',
        }}
        >
          <AddImage onImageChanged={(file: File | null) => onImageChanged(file)} existingImgUrl={edit ? state.imgUrl : ''} buttonTitle="Material bild" />
          <TextField
            color="primary"
            onChange={onInputChange('title')}
            label="Title"
            value={state.title}
          />

          <DescriptionForm title="Skapa kort beskrivning" existingDescription={state.shortDescription} onDescriptionChanged={(shortDescription: string) => setState({ ...state, shortDescription })} />

          <DescriptionForm title="Skapa beskrivning" existingDescription={state.description} onDescriptionChanged={(description: string) => setState({ ...state, description })} />

          <FormControl>
            <InputLabel>Sub categories</InputLabel>
            <Select variant="outlined" label="Sub categories" value={state.subCategoryId === null ? '' : state.subCategoryId} onChange={(event) => setState({ ...state, subCategoryId: event.target.value as MaterialCategory })}>
              {materialSubCategories.map((v) => <MenuItem key={v.id} value={v.id}>{v.value}</MenuItem>)}
            </Select>
          </FormControl>

          <AddFile onFileChanged={(file: File | null) => onFileChanged(file)} buttonTitle="Ladda upp PDF" existingFileUrl={edit ? state.downloadUrl : ''} />

          <Button variant="outlined" type="submit" onClick={() => onSubmitClicked()}>Save</Button>
        </div>
      </>
    );
}

export default CreateMaterialForm;
