import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import * as React from 'react';

type Props = {
  imagePreview: string;
  useImg: () => void;
  open: boolean;
  handleClose: () => void;
};

function AddImageDialog(props: Props) {
  const {
    useImg, handleClose, imagePreview, open,
  } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}> Förhandsgranska bild</Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{ justifyContent: 'center', display: 'flex' }}>
        <img
          src={imagePreview}
          alt="imagepreview"
          style={{
            objectFit: 'cover', width: '100%', boxShadow: '0px 3px 6px #00000029', borderRadius: 10, height: 200,
          }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginBottom: 10 }}>
        <Button
          color="primary"
          variant="contained"
          className="add-image-save-btn"
          onClick={useImg}
          style={{
            color: '#ffffff', height: 40, fontSize: 15, textTransform: 'none', borderRadius: 10, width: 190,
          }}
        >
          Använd
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddImageDialog;
